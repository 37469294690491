/**
 *
 * 要素をウィンドウの高さにする
 *
 */
export default function () {


  let winHeight = window.document.documentElement.clientHeight + "px";
  const mv = document.getElementById('mv');
  const pride = document.getElementById('pride');
  const history = document.getElementById('history');
  const historyBlock = document.querySelectorAll('.history__block'); // コンテンツの取得


  mv.style.height = winHeight;
  pride.style.minHeight = winHeight;

  if (window.matchMedia('(min-width: 1070px)').matches) {
    history.style.height = winHeight;
    for (var i = 0; i < historyBlock.length; i++) {
      historyBlock[i].style.minHeight = '100%';
      historyBlock[i].style.height = '100%';
    }

  } else {
    history.style.height = 'auto';
    for (var i = 0; i < historyBlock.length; i++) {
      historyBlock[i].style.minHeight = winHeight;
      historyBlock[i].style.height = 'auto';
    }
  }

}
