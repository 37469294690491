/**
 *
 * ロード画面
 *
 */

import news from './news.js'


export default function () {

  var time = new Date().getTime();
  $(function () {
    $('.load').css('display', 'block');
    $('.load img').delay(500).fadeIn(4000);
  });

  //全ての読み込みが完了したら実行
  $(window).on('load', function () {
    var now = new Date().getTime();
    if (now - time <= 3000) {
      setTimeout(function () {
        stopload();
      }, 5000 - (now - time));
    } else {
      stopload();
    }
  });

  //10秒たったら強制的にロード画面を非表示
  $(function () {
    setTimeout(function () {
      stopload();
    }, 10000);
  });


  function stopload() {
    document.getElementById('js-loadMovie').play();
    setTimeout(function () {
      $('.load img').fadeOut(1000);
    }, 4500);
    setTimeout(function () {
      $('.l-content-wrap').css('display', 'block');
      $('.load').fadeOut(800);
      $('.load-mainBg').delay(300).fadeOut(2000);

      news()

    }, 5000);
  }








  /*
  $(function () {
    $('.load').css('display', 'block');
  });

  $(window).on('load', function () { //全ての読み込みが完了したら実行
    $.when(
      document.getElementById('js-loadMovie').play(),
      $('.load').delay(900).fadeOut(800),
      $('.l-content-wrap').css('display', 'block')
    ).done(function () {
      setTimeout(function () {
        popup();
      }, 2000);
    });
  });

  //10秒たったら強制的にロード画面を非表示
  $(function () {
    setTimeout(function () {
      stopload();
    }, 10000);
  });


  function stopload() {
    $('.l-content-wrap').css('display', 'block');
    $('.load').delay(900).fadeOut(800);
  }

  function popup() {
    $('.mv').addClass('js-popup')
  }
  */


}




















