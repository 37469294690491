/**
 * menu
 */

export default function () {

  // ハンバーガー
  const menuIcon = document.getElementById('menuBtn');
  const header = document.getElementById('header');
  menuIcon.addEventListener("click", function () {
    header.classList.toggle('js-active');
  });


  // メニュークリックしたら閉じる
  let targets = document.getElementsByClassName('header__item');
  for (let i = 0; i < targets.length; i++) {
    targets[i].addEventListener('click', () => {
      header.classList.remove('js-active');
    }, false);
  }

}
