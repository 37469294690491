/**
 * news
 */

export default function () {

  const txts = document.getElementsByClassName('mv__news-item');
  let txtIndex = -1;

  let changeTxt = function () {
    txtIndex++;

    let currentNum = txtIndex % txts.length;
    let nextNum = (txtIndex + 1) % txts.length;
    let current = txts[currentNum];
    let next = txts[nextNum];

    setTimeout(function () {
      current.classList.remove('js-active');
    }, 5000);
    setTimeout(function () {
      current.style.display = 'none';
      next.style.display = 'flex';
    }, 6500);
    setTimeout(function () {
      next.classList.add('js-active');
    }, 7000);
  }

  function showNextTxt(changeTxt) {
    setInterval(changeTxt, 9000);
  }

  changeTxt();
  showNextTxt(changeTxt);
}
