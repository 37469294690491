/**
 *
 * 要素をフワッと表示させる
 *
 */

export default function () {
  $(function () {
    $(window).scroll(function () {
      $('.effect-fade').each(function () {
        var elemPos = $(this).offset().top;
        var elemBottom = $(this).height() + elemPos;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > elemPos - windowHeight && scroll < elemBottom) {
          $(this).addClass('effect-scroll');
        } else {
          $(this).removeClass('effect-scroll');
        }
      });
    });
  });

}
