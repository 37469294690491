/**
 * object-fit: のPolyfill
 * DOM読み込み後に実行する
 * img要素のみ有効
 * 【CSSについて】
 * object-fitを使用する要素にfont-familyを追加して必要な値を設定する。
 * 例. font-family: 'object-fit: cover; object-position: bottom;';
 * 【PostCSS】
 * postcss-object-fit-images
 * font-familyに設定する値はPostCSSで自動的に追加するできる
 */
import objectFitImages from 'object-fit-images'

export default function() {
  objectFitImages()
}
