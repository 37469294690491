/**
 * ページトップ
 */

export default function () {

  $(function () {

    // ◇ボタンをクリックしたら、スクロールして上に戻る
    $('.header__head-btn').click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 500);
      return false;
    });

  });
}
