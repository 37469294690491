/*
製品
*/
export default function () {
  
  var _ = require('lodash');
  
  var contents = document.querySelectorAll('.history__block'); // コンテンツの取得
  var count = 1; // 表示させるコンテンツの番号を設定
  var countFlg = false; // ホイールのイベントをやたらめったに取得しないためのフラグ
  
  // 上スクロールか下スクロールかの判定 上でtrue
  var sc = (function () {
    var scrollElement = 'scrollingElement' in document ? document.scrollingElement : document.documentElement;
    var scrollPoint, prePoint, flag;
    return function () {
      scrollPoint = scrollElement.scrollTop;
      flag = prePoint > scrollPoint ? true : false;
      prePoint = scrollPoint;
      return flag;
    }
  })();
  
  // 要素の位置等取得
  let history_el = $('#history').offset().top;
  // ページ上からのスクロールした高さを取得
  let scroll_amount = $(this).scrollTop();
  
  // ==========
  // リサイズ処理
  // ==========
  window.addEventListener('resize', function () {
    "use strict";
  
    // それぞれの数値を更新
    history_el = $('#history').offset().top;
    scroll_amount = $(this).scrollTop();

    // history要素外でjs-fixedがつかないように。
    // スクロール量が
    if (window.matchMedia('(min-width: 1070px)').matches && (scroll_amount < history_el || scroll_amount > (history_el + window.document.documentElement.clientHeight))) {
      $(body).removeClass('js-fixed');
      $(body).addClass('js-unlock');
    }
  
  
  }, false);
  
  window.addEventListener("scroll", function () {
    // スクロール量を変数に格納
    scroll_amount = $(this).scrollTop();
  
    // 上スクロールでtrue
    let isScrollUp = sc()
  
    if ((isScrollUp && scroll_amount <= history_el && ($('#lastBlock').hasClass('show'))) || (!isScrollUp && scroll_amount >= history_el && (!$('#lastBlock').hasClass('show')))) {
      // 要素を固定する
      $(body).addClass('js-fixed');
      $(body).removeClass('js-unlock');
  
      if ($(body).hasClass('js-fixed') && (window.matchMedia('(min-width: 1070px)').matches)) {
        $(window).scrollTop(history_el);
      }
    }
  });
  // ヒストリー内 マウスホイール処理
  document.getElementById('history').addEventListener('wheel', _.throttle((e) => {
  
    if ($(body).hasClass('js-fixed') && !countFlg) {
      // ホイールが下方向だったら
      if (e.deltaY > 0) {
        // countの値をプラス
        count++;
        // countの値の上限をコンテンツの数とする
        if (count >= contents.length) {
          count = contents.length;
        }
        if ($('#lastBlock').hasClass('show')) {
          $(body).removeClass('js-fixed');
          $(body).addClass('js-unlock');
        }
      }
      // ホイールが上方向だったら
      else if (e.deltaY < 0) {
        //countの値をマイナスにする
        count--;
        // countの値の下限を1とする
        if (count <= 1) {
          count = 1;
        }
  
        if ($('#firstBlock').hasClass('show')) {
          $(body).removeClass('js-fixed');
          $(body).addClass('js-unlock');
        }
      }
      // countFlgをtrueにする
      countFlg = true;
  
      // 数秒後、countFlgをfalseにして、またホイールのイベントで動くように
      setTimeout(function () {
        countFlg = false;
      }, 1000); // 秒数を指定。ミリ秒
      // 一旦コンテンツを全部非表示にし、
      for (var i = 0; i < contents.length; i++) {
        contents[i].classList.remove('show'); // showクラスを削除して非表示に
      }
      // 該当コンテンツのみ表示
      contents[count - 1].classList.add('show'); // showクラスを付与して表示
    }
    return false;
  }, 2000, { trailing: false, leading: true }));

}
