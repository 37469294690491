/**
 * historyのスライダー
 */

export default function () {

  const body_el = document.getElementById('body');
  const pride_el = document.getElementById('headerPride');
  const history_el = document.getElementById('headerHistory');
  const value_el = document.getElementById('headerValue');
  const products_el = document.getElementById('headerProducts');
  const gallery_el = document.getElementById('headerGallery');
  const instagram_el = document.getElementById('headerInstagram');
  const sliderFirst_el = document.getElementById('firstBlock');
  const sliderLast_el = document.getElementById('lastBlock');
  const sliderAll_el = document.getElementsByClassName('history__block');

  // クリックした際に実行する処理を記述
  function menuClick() {
    if (body_el.classList.contains('js-fixed')) {
      body_el.classList.remove('js-fixed');
    }
    var targetElements = document.getElementsByClassName('history__block');
    [].forEach.call(targetElements, function (elem) {
      elem.classList.remove('show');
    })
  }


  // ヘッダーメニューをクリックした時に反応させる
  const headerMenu_el = document.getElementsByClassName('header__item');
  for (let i = 0; i < headerMenu_el.length; i++) {
    headerMenu_el[i].addEventListener('click', menuClick);
  }


  pride_el.addEventListener('click', () => {
    sliderFirst_el.classList.add('show');
  })

  history_el.addEventListener('click', () => {
    sliderFirst_el.classList.add('show');
  })

  value_el.addEventListener('click', () => {
    sliderLast_el.classList.add('show');
  })

  products_el.addEventListener('click', () => {
    sliderLast_el.classList.add('show');
  })

  gallery_el.addEventListener('click', () => {
    sliderLast_el.classList.add('show');
  })

  instagram_el.addEventListener('click', () => {
    sliderLast_el.classList.add('show');
  })

}
