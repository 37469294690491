let winWidth = window.document.documentElement.clientWidth;

import objectFitImages from './polyfill/objectFitImages' // object-fit-images
import fadeEffects from './component/fadeEffects.js'
import loadEffects from './component/loadEffects.js'
import menu from './component/menu.js'
import bird from './component/bird.js'
import product from './component/product.js';
import historySlider from './component/historySlider.js';
import windowsHeight from './component/windowsHeight.js'
import pageTop from './component/pageTop.js'
import news from './component/news.js'


// ==========
// DOMツリー構築終了後に実行
// ==========
document.addEventListener('DOMContentLoaded', function () {
  "use strict";

  objectFitImages()

}, false);


// ==========
// リサイズ処理
// ==========
window.addEventListener('resize', function () {
  "use strict";

  // ウィンドウ高さ取得
  windowsHeight()

}, false);


// ==========
// DOM、画像読み込み後に実行
// ==========
window.addEventListener('load', function () {
  "use strict";

  fadeEffects()
  windowsHeight()
  menu()
  bird()
  historySlider()
  product()
  pageTop()

}, false);



// ==========
// レスポンシブ
// ==========
var webStorage = function () {
  const body = document.getElementById('body');
  const loadBg = document.getElementsByClassName('load-mainBg');
  if (sessionStorage.getItem('access')) {
    body.classList.add('js-load-already');
    //loadEffects()
    loadBg[0].style.display = 'none';

    news()

  } else {
    sessionStorage.setItem('access', 0);
    body.classList.add('js-load-notYet');
    loadEffects()
  }
}

webStorage();
