/**
 * bird
 */

export default function () {

  $('.bird-img').removeClass('js-active');

  // 画面内にあるか
  $(window).scroll(function () {
    var footer_el = $('.footer').offset().top; // ターゲットの位置取得
    var footer_el_bottom = footer_el - $(window).height();  // 画面下部からのターゲットの位置
    if ($(window).scrollTop() > (footer_el_bottom + 200)) {
      $('.footer').addClass('js-show');
    } else {
      $('.footer').removeClass('js-show');
    }

    if ($('.footer').hasClass('js-show')) {
      $('.bird-img').eq(0).addClass('js-active');
      var i = 1;
      setInterval(function () {
        $('.bird-img').eq(i).addClass('js-active');
        i++;
        if (i >= $('.bird-img').length) i = 0;
      }, 1000);
    }
  })




  // 画像のファイル名変換
  var mql = window.matchMedia('screen and (min-width: 1070px)');
  var $elem = $('.bird-img');

  function checkBreakPoint(mql) {

    $elem.each(function () {
      var $this = $(this);
      if (mql.matches) {
        // PC向け
        $this.attr('src', $this.attr('src').replace('sp', 'pc'));
      } else {
        // スマホ向け
        $this.attr('src', $this.attr('src').replace('pc', 'sp'));
      }
    });
  }


  // ブレイクポイントの瞬間に発火
  mql.addListener(checkBreakPoint);

  // 初回チェック
  checkBreakPoint(mql);


}
